import {calcData, tenTo16} from '../global.ts';
const CommandData:any = {
    'GamingKeyboard': {
        beginConnect: '0x80', //开始通讯
        endConnect: '0x81', //结束通讯
        getDeviceMessage: '0x82', //读取设备信息
        setDeviceMessage: '0x83', //设定设备信息
        getdData: '0x84',//读取通用信息
        setData: '0x85',//设定通用信息
        getKeyboard: '0x86',//读取默认按键信息
        getkeyMessage: '0x87',//读取按键信息
        setkeyMessage: '0x88',//设定按键信息
        getlightMessage:'0x89',//读取自定义灯光信息
        setlightMessage:'0x8a',//设定自定义灯光信息
        gethongMessage:'0x8b',//读取宏信息
        sethongMessage:'0x8c',//设定宏信息
        tbLightSetting:'0x8d',//同步控制灯光
        closetbLightSetting: '0x8e',//关闭同步控制灯光
        getPoorNum: '0x8f', //读取电池状态(电量、充电)
        restKeyBoard: '0x90', //复位
        getLightRect: '0x91',//读取灯光矩阵信息
        getProFile:'0xa0',//读取当前PROFILE 1个Byte
        setProFile:'0xa1',//设定(切换)PROFILE 1个Byte
        getFnDefaultMessage: '0xa2',//读取FN默认按键信息
        getFnMessage: '0xa3',//读取FN按键信息
        setFnMessage: '0xa4',//设定FN按键信息
        setFnLockList: '0xa5',//获取FN组合不可修改列表 64*4=256
        checkConnect: '0x55' //检查 2.4g连接
    },
    'Z108':{
        beginConnect: '0x10', //开始通讯
        endConnect: '0x11', //结束通讯
        getDeviceMessage: '0x12', //读取设备信息
        setDeviceMessage: '0x13', //设定设备信息
        getdData: '0x14',//读取通用信息
        setData: '0x15',//设定通用信息
        getKeyboard: '0x16',//读取默认按键信息
        getkeyMessage: '0x17',//读取按键信息
        setkeyMessage: '0x18',//设定按键信息
        getlightMessage:'0x19',//读取自定义灯光信息
        setlightMessage:'0x1a',//设定自定义灯光信息
        gethongMessage:'0x1b',//读取宏信息
        sethongMessage:'0x1c',//设定宏信息
        tbLightSetting:'0x1d',//同步控制灯光
        closetbLightSetting: '0x1e',//关闭同步控制灯光
        getPoorNum: '0x1f', //读取电池状态(电量、充电)
        restKeyBoard: '0x20', //复位
        getLightRect: '0x21',//读取灯光矩阵信息
        getProFile:'0x30',//读取当前PROFILE 1个Byte
        setProFile:'0x31',//设定(切换)PROFILE 1个Byte
        getFnDefaultMessage: '0x32',//读取FN默认按键信息
        getFnMessage: '0x33',//读取FN按键信息
        setFnMessage: '0x34',//设定FN按键信息
        setFnLockList: '0x35',//获取FN组合不可修改列表 64*4=256
        checkConnect: '0x55' //检查 2.4g连接
    }
}

const Commands:any = {
    "49288": CommandData.GamingKeyboard,
    "49271": CommandData.Z108,
    "12410": CommandData.Z108,
    "12312": CommandData.Z108,
};

// 按键命令
export const Command = {
    c: Commands['12312'],
    set(id:string) {
        this.c = Commands?.[id] || Commands['12312'];
    },
    get(str:string) {
        return str && this.c?.[str]  ? this.c?.[str] : '';
    }
};

const hongWay = (id:string, data:Array<string>, place:Array<string> = ['00', '00']) => {
    let arr = [id, ...place, tenTo16(data.length), '00', '00', '00', ...data]
    const jy:string[] = calcData(arr);
        arr[4] = jy[0];
        arr[5] = jy[1];
    return arr;
}

const way = (id:string, data:Array<string>, place:Array<string> = ['00', '00'], reqlen: number = 63) => {
    let arr = [id, ...place, tenTo16(reqlen-7), '00', '00', '00', ...data]
    const jy:string[] = calcData(arr);
        arr[4] = jy[0];
        arr[5] = jy[1];
    return arr;
}
// const getWay = (id:string, data:Array<string>, place:Array<string> = ['00', '00']) => {
//     let arr = [id, ...place, '0x38', '00', '00', '00', ...data]
//     const jy:string[] = calcData(arr);
//         arr[4] = jy[0];
//         arr[5] = jy[1];
//     return arr;
// }

export const CommandWay = (
    code:string, //操作 Command
    data:Array<string>, //请求数据
    place:Array<string>, //查询数据位置
    reqlen:number //数据有效位
):string[] => {

    switch(code){
        case Command.get('beginConnect'):
        // 开始通信
        return way(Command.get('beginConnect'), data, place, reqlen);
        case Command.get('endConnect'):
        //结束通讯
        return way(Command.get('endConnect'),  data, place, reqlen);
        case Command.get('getDeviceMessage'):
            //读取设备信息
        return way(Command.get('getDeviceMessage'),  data, place, reqlen);
        case Command.get('setDeviceMessage'):
            //读取设备信息
        return way(Command.get('setDeviceMessage'),  data, place, reqlen);
        case Command.get('getdData'):
            //设定设备信息
        return way(Command.get('getdData'),  data, place, reqlen);
        case Command.get('setData'):
            //设定通用信息
            return way(Command.get('setData'),  data, place, reqlen);
        case Command.get('getKeyboard'):
            //读取默认按键信息
            return way(Command.get('getKeyboard'),  data, place, reqlen);
        case Command.get('getkeyMessage'):
            //读取按键信息
            return way(Command.get('getkeyMessage'),  data, place, reqlen);
        case Command.get('setkeyMessage'):
            //设定按键信息
            return way(Command.get('setkeyMessage'),  data, place, reqlen);
        case Command.get('getlightMessage'):
            //读取自定义灯光信息
            return way(Command.get('getlightMessage'),  data, place, reqlen);
        case Command.get('setlightMessage'):
            //设定自定义灯光信息
            return way(Command.get('setlightMessage'),  data, place, reqlen);
        case Command.get('gethongMessage'):
            //读取宏信息
            return way(Command.get('gethongMessage'),  data, place, reqlen);
        case Command.get('sethongMessage'):
            //设定宏信息
            return hongWay(Command.get('sethongMessage'),  data, place);
        case Command.get('tbLightSetting'):
            //同步控制灯光
            return way(Command.get('tbLightSetting'),  data, place, reqlen);
        case Command.get('closetbLightSetting'):
            //读取设备信息
            return way(Command.get('closetbLightSetting'),  data, place, reqlen);
            //关闭同步控制灯光
        case Command.get('getPoorNum'):
            //读取电池状态(电量、充电)
            return way(Command.get('getPoorNum'),  data, place, reqlen);
        case Command.get('restKeyBoard'):
            //复位
            return way(Command.get('restKeyBoard'),  data, place, reqlen);
        case Command.get('getLightRect'):
            //读取灯光矩阵信息
            return way(Command.get('getLightRect'),  data, place, reqlen);
        case Command.get('getProFile'):
            //读取当前PROFILE 1个Byte
            return way(Command.get('getProFile'),  data, place, reqlen);
        case Command.get('setProFile'):
            //设定(切换)PROFILE 1个Byte
            return way(Command.get('setProFile'),  data, place, reqlen);
        case Command.get('getFnDefaultMessage'):
            //读取FN默认按键信息
            return way(Command.get('getFnDefaultMessage'),  data, place, reqlen);
        case Command.get('getFnMessage'):
            //读取FN按键信息
            return way(Command.get('getFnMessage'),  data, place, reqlen);
        case Command.get('setFnMessage'):
            //设定FN按键信息
            return way(Command.get('setFnMessage'),  data, place, reqlen);
        case Command.get('setFnLockList'):
            //获取FN组合不可修改列表 64*4=256
            return way(Command.get('setFnLockList'),  data, place, reqlen);
        case Command.get('checkConnect'):
            //检查 2.4g连接
            return way(Command.get('checkConnect'),  data, place, reqlen);
        default:
            return []
    }
    
}

type respType = {
    status: boolean,
    place?: number[],
    data?: any,
}

export const getDataWay = (code:string,
    data:number[], 
    {
        default_key_Rect,
        key_Rect,
        setting,
        light_rect
    }:any,
    reqLen:number
):respType => {
    switch(code) {
        case Command.get('getDeviceMessage'):
            //读取设备信息
            return {
                status: data[6] === 85,
                data: data[6] === 85 ? {
                    _productId: parseInt(`${data[10].toString(16)}${data[9].toString(16)}`, 16),
                    valid_data: parseInt(`${data[8].toString(16)}${data[7].toString(16)}`, 16) === 0,
                    key_rect_size: data[11] * 3,
                    hong_space_size: data[12] * 256,
                }:null
            }; 
        case Command.get('setDeviceMessage'):
            //设定设备信息
            return {
                status: data[6] === 85,
                place: [0, 0],
                data: data.filter((_, i) => i > 6)
            };

        case Command.get('getdData'):
            //读取通用信息 (64位置)
            let tydata:string[] = data.filter((_, i) => i > 6).map((item) => tenTo16(item)); //查询通用信息的结果
            if (tydata.length < 64) {
                let i = 64 - tydata.length;
                while(i--) tydata.push('0x00');
            }
            console.log(tydata,"tydata")
            return {
                status: data[6] === 85,
                place: [0, 0],
                data: {
                    setting: tydata,
                    tysetting: tydata
                }
            };

        case Command.get('setData'):
            const tynum2 = parseInt(`${data[2].toString(16)}${data[1].toString(16)}`, 16);
            //设定通用信息
            const tydata1 = tynum2 > 0 ? 
                setting:
                data.filter((_, i) => i > 6).map((item) => tenTo16(item)).concat(['00','00','00','00','00','00','00','00']);
            return {
                status: data[6] === 85,
                place: [0, 0],
                data: {
                    setting: tydata1,
                }
            }

        case Command.get('getKeyboard'):
            const placenum1 = parseInt(`${data[2].toString(16)}${data[1].toString(16)}`, 16);
            // console.log(placenum1, 'default_key_Rect_placenum')
            let arr1 = [...default_key_Rect];
            for (let key = 0; key < 56; key++) {
                arr1[placenum1+key] = data[7+key];
            }
            //读取默认按键信息
            return {
                status: data[6] === 85,
                place: [placenum1, placenum1+reqLen-7],
                data: {
                    default_key_Rect: arr1
                }
            };

        case Command.get('getkeyMessage'):
            const placenum2 = parseInt(`${data[2].toString(16)}${data[1].toString(16)}`, 16);
            // console.log(placenum2, 'key_Rect')
    
            let arr2 = [...key_Rect];
            for (let key = 0; key < 56; key++) {
                arr2[placenum2+key] = data[7+key];
            }
            //读取按键信息
            return {
                status: data[6] === 85,
                place: [placenum2, placenum2+reqLen-7],
                data: {
                    key_Rect: arr2
                }
            }

        case Command.get('setkeyMessage'):
            const placenum = parseInt(`${data[2].toString(16)}${data[1].toString(16)}`, 16);
            // console.log(placenum, 'key_Rect')
    
            let arr = [...key_Rect];
            for (let key = 0; key < 56; key++) {
                arr[placenum+key] = data[7+key];
            }
            //设定按键信息
            return {
                status: data[6] === 85,
                place: [placenum, placenum+reqLen-7],
                data: {
                    key_Rect: arr
                }
            };

        case Command.get('getlightMessage'):
            const placenum3 = parseInt(`${data[2].toString(16)}${data[1].toString(16)}`, 16);
            // console.log(placenum3, 'light_rect')
    
            let arr3 = [...light_rect];
            for (let key = 0; key < 56; key++) {
                arr3[placenum3+key] = data[7+key];
            }
            //读取自定义灯光信息
            return {
                status: data[6] === 85,
                place: [placenum3, placenum3+reqLen-7],
                data: {
                    light_rect: arr3
                }
            };
        case Command.get('setlightMessage'):
            const placenum4 = parseInt(`${data[2].toString(16)}${data[1].toString(16)}`, 16);
            // console.log(placenum4, 'light_rect')
    
            let arr4 = [...light_rect];
            for (let key = 0; key < 56; key++) {
                arr4[placenum4+key] = data[7+key];
            }
            //设定自定义灯光信息
            return {
                status: data[6] === 85,
                place: [placenum4, placenum4+reqLen-7],
                data: {
                    light_rect: arr4
                }
            };

        // Command.get('gethongMessage'):
        //     //读取宏信息
        //     // return way(Command.get('gethongMessage'), data, place);
        // },
        case Command.get('sethongMessage'):
            //设定宏信息
            return {
                status: data[6] === 85,
                place: [data[2], data[1]],
                data: {
                    hong: [],
                }
            };

            // case Command.get('tbLightSetting'):
            //同步控制灯光
            // return way(Command.get('tbLightSetting'), data, place);

            // case Command.get('closetbLightSetting'):
            //读取设备信息
            // return way(Command.get('closetbLightSetting'), data, place);
            //关闭同步控制灯光
        case Command.get('getPoorNum'):
            // 读取电池状态(电量、充电)
            return {
                status: data[6] === 85,
                place: [0, 0],
                data: {
                    poor: data[7],
                    poorStatus: data[8],
                }
            };

        case Command.get('restKeyBoard'):
            // 复位
            return {
                status: data[6] === 85,
            };
        // case Command.get('getLightRect'):
            //读取灯光矩阵信息
            // return way(Command.get('getLightRect'), data, place);

        case Command.get('getProFile'):
            //读取当前PROFILE 1个Byte
            return {
                status: data[6] === 85,
                place: [0, 0],
                data: {
                    profileid: data.filter((_, i) => i > 6)
                }
            };

        case Command.get('setProFile'):
            //设定(切换)PROFILE 1个Byte
            return {
                status: data[6] === 85,
                place: [0, 0],
                data: data.filter((_, i) => i > 6)
           };

        // case Command.get('getFnDefaultMessage'):
        // 读取FN默认按键信息
        // return way(Command.get('getFnDefaultMessage'), data, place);

        // Command.get('getFnMessage'):
        //     //读取FN按键信息
        //     // return way(Command.get('getFnMessage'), data, place);
        case Command.get('setFnMessage'):
            //设定FN按键信息
            return {
                status: data[6] === 85,
                place: [0, 0],
                data: data.filter((_, i) => i > 6)
           };
        case Command.get('checkConnect'):
            return {
                status: true,
                place: [0, 0],
                data: {
                    hasSleep: data[7] ? false:true,
                }
           };

        // case Command.get('setFnLockList'):
        //     //获取FN组合不可修改列表 64*4=256
        //     // return way(Command.get('setFnLockList'), data, place);
        default:
            return {
                status: data[6] === 85,
                place: [0, 0],
                data: {
                    hasSleep: ""
                }
           };

    }
    
}

export const tyReportId = '0x06';