import React, { useState } from 'react';
import {Row, Col, Radio, Tabs } from 'antd';
import Advanced from './pages/advanced/advanced.tsx';
import LightSetting from './pages/lightset/lightSetting.tsx';
import Custom from './pages/custom/custom.tsx';
import Performanc from './pages/changeKey/performanc.tsx';

// import './index.less';

const { TabPane } = Tabs;

const MyApp = ({t}:any) => {
    const [mode, setMode] = useState<string>('1');
    const handleModeChange = (mode:string) => {
        setMode(mode);
    };
    const rdstyle:any = {
        width: '25%',
        height: '45px',
        // lineHeight: '45px',
        textAlign: 'center',
    }
    return (
       <Row className='_content'>
            <Col span={24}>
                <Tabs activeKey={mode} style={{overflowY:'hidden',overflowX:'hidden' }}>
                    <TabPane key="1">
                        <LightSetting t={t} />  
                    </TabPane>
                    <TabPane key="2">
                        <Custom t={t} />
                    </TabPane>
                    <TabPane key="3">
                        <Performanc t={t} />
                    </TabPane>
                    <TabPane key="4">
                        <Advanced t={t} />
                    </TabPane>
                </Tabs>
                
            </Col>
            <Col span={24} className='tab-btn'>
                <Radio.Group onChange={(e) => handleModeChange(e?.target?.value)} value={mode}
                    style={{ width: '100%',marginBottom: 0}}>
                    <Radio.Button value="1" style={rdstyle}>{`${t('lightset')}`}</Radio.Button>
                    <Radio.Button value="2" style={rdstyle}>{`${t('keysset')}`}</Radio.Button>
                    <Radio.Button value="3" style={rdstyle}>{`${t('hongsset')}`}</Radio.Button>
                    <Radio.Button value="4" style={rdstyle}>{`${t('othersset')}`}</Radio.Button>
                </Radio.Group>
            </Col>
       </Row>
    )
}

MyApp.displayName = 'MyApp';
export default MyApp;